<h1 mat-dialog-title>{{ data.title ?? 'common.warning' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div>{{ data.message | translate: data.translationValues ?? {} }}</div>
    <sz-dialog-button-bar
        [primaryText]="'btn.confirm' | translate"
        [primaryColor]="'primary'"
        [primaryHidden]="data.hideConfirm ?? false"
        (primaryClick)="dialogRef.close(true)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
