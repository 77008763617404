import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface WarningDialogData {
    title: string;
    message: string;
    translationValues?: Record<string, string>;
    hideConfirm?: boolean;
}

@Component({ templateUrl: './warning-dialog.component.html' })
export class WarningDialog {
    constructor(
        public dialogRef: MatDialogRef<WarningDialog>,
        @Inject(MAT_DIALOG_DATA) public data: WarningDialogData,
    ) {}
}
